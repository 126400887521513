import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }
  /**
  * Formats the profile name.
  * @param profile - The profile name to be formatted.
  */

  getFormattedProfileName(profile: string) {
    switch (profile) {
        case 'SYSTEM_DEFAULT':
            return 'Default System Profile';
        case 'SYSTEM_VAV_ANALOG_RTU':
        case 'vavFullyModulatingAhu':
            return 'VAV Fully Modulating AHU';
        case 'SYSTEM_VAV_STAGED_RTU':
        case 'vavStagedRtu':
            return 'VAV Staged RTU';
        case 'SYSTEM_VAV_HYBRID_RTU':
            return 'VAV Advanced Hybrid AHU';
        case 'SYSTEM_VAV_STAGED_VFD_RTU':
        case 'vavStagedVfdRtu':
            return 'VAV Staged RTU with VFD Fan';
        case 'SYSTEM_VAV_IE_RTU':
            return 'VAV Daikin IE';
        case 'SYSTEM_DAB_IE_RTU':
            return 'DAB Daikin IE';
        case 'SYSTEM_DAB_ANALOG_RTU':
            return 'DAB Fully Modulating AHU';
        case 'dabStagedRtu':
        case 'SYSTEM_DAB_STAGED_RTU':
            return 'DAB Staged RTU';
        case 'SYSTEM_DAB_HYBRID_RTU':
            return 'DAB Advanced Hybrid AHU';
        case 'dabStagedRtuVfdFan':
        case 'SYSTEM_DAB_STAGED_VFD_RTU':
            return 'DAB Staged RTU with VFD Fan';
        case 'SMARTSTAT_FOUR_PIPE_FCU':
            return 'SS-4P FCU';
        case 'SMARTSTAT_TWO_PIPE_FCU':
            return 'SS-2P FCU';
        default:
            return profile;
    }
  }

  getFormattedColumnName(columnName: string) {
    switch (columnName) {
      case 'deliveredDateTime':
        return 'Delivered Date & Time';
      case 'ackedDateTime':
        return 'Confirmed Receipt';
      case 'ccuName':
        return 'System';
      case 'requestTime':
          return 'Request Time';
      default:
        return columnName;
    }
  }

}
